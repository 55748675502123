import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailPipe',
  standalone: true
})
export class EmailPipe implements PipeTransform {

  transform(value: any, replaceValue?: any, isZeroValid: boolean = false): any {
    if (isZeroValid) return value || value === 0 ? "-" : replaceValue;
    else return value ? value : replaceValue;
  }

}
