import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService } from './services/shared.service';
import { EmptyPipe } from './pipes/empty.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { EnumMapperPipe } from './pipes/enum-mapper.pipe'
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressDirective } from './directives/progress.directive';
import { EmailPipe } from './pipes/email.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { IfPrivilegeDirective } from './directives/if-privilege.directive';

const items = [EmptyPipe,
  CustomCurrencyPipe,
  ProgressDirective,
  EmailPipe,
  IfPrivilegeDirective,
  EnumMapperPipe,
  PhonePipe]

@NgModule({
    imports: [CommonModule,
        ModalModule.forRoot(),
        items
    ],
    providers: [SharedService],
    // declarations: [...items],
    exports: [...items]
})
export class UtilsModule { }
