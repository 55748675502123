import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import {
  SharedService, NoRowsOverlayFlags, ScreenActionPrivileges, SnackbarConfig, CapType,
  GridMaster, StorageKey, BrowserStorageService, AuthUtils
} from '@wlms-web/utils';
import { EditDailyCapComponent } from './edit-daily-cap/edit-daily-cap.component';
import { WarehouseLinesService } from '../../services/whl.service';
import { UiHelperService } from '@wlms-web/ui-kit';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { WarehouseLinesDailyCapdetails } from '../../config/table-structure-dailycaps-availability';
import * as moment from 'moment';
import { ResetPreferenceComponent } from 'libs/ui-kit/src/lib/reset-preference/reset-preference.component';

@Component({
  selector: 'wlms-daily-caps',
  templateUrl: './daily-caps.component.html',
  styleUrls: ['./daily-caps.component.scss']
})
export class DailyCapsComponent implements OnInit {

  rowData = [];
  columnDefs = [...WarehouseLinesDailyCapdetails];
  paginationPageSize = 50;
  screenActionPrivileges = ScreenActionPrivileges;
  @Input() suppressActionPrivilege = false;
  @Input() suppressRightClickPrivilege = false;
  noRowsOverlayFlags = { ...NoRowsOverlayFlags };
  @ViewChild('dailyCapsGrid') dailyCapsGrid: any;
  @Output() warehouseReloaded: EventEmitter<any> = new EventEmitter<any>();

  preferance: any[] = [];
  filterPreferance: any[] = [];
  filterModel: any;

  columnMaster = [];
  selectedTabPreference: any[] = [];

  constructor(private warehouselinesservice: WarehouseLinesService,
    public sharedService: SharedService,
    private authUtils: AuthUtils,
    public modalService: BsModalService,
    private browserStorageService: BrowserStorageService,
    private router: Router,
    private uiHelperService: UiHelperService) { }

  ngOnInit(): void {
    this.sharedService.disableRestButton  = false;
    this.getColumnMaster();
    this.getWarehouselineDailyCapData();
    this.getUserPreference();
  }

  getWarehouselineDailyCapData() {
    this.rowData = [];
    this.sharedService.loadGrid.next({ isLoad: true });
    return this.warehouselinesservice.getDailyCapAvailability()
      .subscribe(response => {
        this.rowData = [...response['details']];
        this.rowData.forEach(x => {
          if (x.CapType == (CapType[CapType.findIndex(y => y.name == 'Available Amount')].id)) {
            x.AvailableAmountCap = x.Value;
            x.LineLimitAmountCap = '';
          } else {
            x.LineLimitAmountCap = x.Value;
            x.AvailableAmountCap = '';
          }
        })
        this.sharedService.loadGrid.next({ isLoad: false });
      });
  }

  openEditDailyCaps(event) {
    const data = {
      isEdit: true,
      dailyCapData: { ...event.data }
    };
    const title = 'Edit Daily Caps';
    this.uiHelperService.askConfirmation(EditDailyCapComponent, 'modal-lg', title, data, 'Cancel', 'Update',
      (data: any) => {
        this.saveWarehouse(data);
      });
  }

  saveWarehouse(data) {
    const dailyCap = {
      ...data.data.dailyCapData,
      StartDate: moment(data.data.dailyCapData.StartDate).startOf('day').utc(true).toISOString(),
      ExpiryDate: moment(data.data.dailyCapData.ExpiryDate).startOf('day').utc(true).toISOString()
    }
    this.warehouselinesservice.SaveEditDailyCapAvailability(dailyCap).subscribe(response => {
      if (response && response['details'] && response['details'].Status == 1) {
        this.getWarehouselineDailyCapData();
        this.uiHelperService.showSnackBar('Edited daily caps Successfully', SnackbarConfig.success);
      }
    })
  }

  noOverlay(event) {
    this.dailyCapsGrid.grid.api.setGridOption("noRowsOverlayComponentParams", {
      noRowsMessageFunc: function () {
        return {
          blankTemplateFlag: 'warehouses',
        };
      }
    });
  }

  saveUserPreferences() {
    this.reorderColumnMaster();
    this.collectUserPreferences();
    this.selectedTabPreference = [];
    this.selectedTabPreference.push({"tabid": GridMaster.DailyCaps});
    const request = {
      Tab: this.selectedTabPreference,
      Column: this.preferance
    };
    this.warehouselinesservice.saveUserPreferences(request).subscribe((response: any) => {
      if (response) {
        this.sharedService.userPreferenceChanged = false;
        this.uiHelperService.showSnackBar('User preferences saved successfully', SnackbarConfig.success);
        this.getUserPreference();
        this.sharedService.disableRestButton  = true;
      }
    }, (error) => {
      this.uiHelperService.showSnackBar('Failed to save user preference', SnackbarConfig.error);
    });
  }

  getColumnMaster() {
    this.warehouselinesservice.getColumnMaster(GridMaster.DailyCaps).subscribe((response: any) => {
      if (response) {
        this.columnMaster = response.details;
        this.reorderColumnMaster();
      }
    });
  }

  reorderColumnMaster() {
    _.forEach(this.columnDefs, (col: any, index: number) => {
      const masterIndex = _.findIndex(this.columnMaster, (master: any) => {
        return master.ColumnName == col.field;
      });
      if (masterIndex != -1) {
        this.columnDefs[index]['ColumnMasterId'] = this.columnMaster[masterIndex].ColumnMasterId;
      }
    });
  }

  collectUserPreferences() {
    this.preferance = [];
    const columnState = this.dailyCapsGrid.getColumnState();
    _.forEach(columnState, (state: any, index: number) => {
      let columnMasterId = null;
      const columnIndex = _.findIndex(this.columnDefs, (col) => {
        return col.field == state.colId;
      });
      if (columnIndex != -1) {
        const col = { ...this.columnDefs[columnIndex] };
        columnMasterId = col['ColumnMasterId'];
        if (columnMasterId > 0) {
          this.preferance.push({
            ColumnMasterId: columnMasterId,
            ColumnOrder: index,
            SortOrder: state.sort,
            GroupOrder: state.rowGroupIndex != null ? state.rowGroupIndex + 1 : state.rowGroupIndex,
            IsVisible: state.hide ? 0 : 1
          });
        }
      }
    });
  }

  getUserPreference() {
    if (this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.GetDailyCapsTabSavePreference)) {
      this.warehouselinesservice.getUserPreferences().subscribe(response => {
        this.persistFilterData();
        if (response) {
          this.preferance = response.details;
          this.sharedService.disableRestButton  = this.preferance.map((pref) => pref.TabId).includes(GridMaster.DailyCaps);
          this.setPreference()
        }
      });
    }
  }

  setPreference() {
    this.reorderColumnMaster();
    const tabPreference = _.filter(this.preferance, (pref: any) => {
      return pref.TabId == GridMaster.DailyCaps;
    });
    if (tabPreference && tabPreference.length > 0) {
      _.forEach(this.columnDefs, (col: any, index: number) => {
        const prefColumn = _.filter(tabPreference, (pref: any) => {
          return pref.ColumnMasterId == col.ColumnMasterId;
        });
        const column = { ...this.columnDefs[index] };
        this.columnDefs[index] = this.updateColumnProps(prefColumn, column);
      });
      const sortedCols = _.sortBy(this.columnDefs, (col: any) => {
        return col['ColumnOrder'];
      });
      this.columnDefs = [...sortedCols];
    } else {
      this.columnDefs = [...WarehouseLinesDailyCapdetails];
    }
  }

  updateColumnProps(prefColumn, column) {
    if (prefColumn && prefColumn.length > 0) {
      column['sort'] = prefColumn[0].SortOrder;
      column['ColumnOrder'] = prefColumn[0].ColumnOrder;
      column['hide'] = prefColumn[0].IsVisible == 1 ? false : true;
      if (prefColumn[0].GroupOrder > 0) {
        column['rowGroup'] = true;
        column['rowGroupIndex'] = prefColumn[0].GroupOrder - 1;
      } else {
        column['rowGroup'] = false;
      }
    }
    return column;
  }

  persistFilterData() {
    this.filterPreferance = [];
    this.filterModel = [];
    this.filterPreferance = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
    const currentFilterData = this.filterPreferance ? this.filterPreferance.filter(x => x.tabId === GridMaster.DailyCaps)[0] : null;
    if (currentFilterData) {
      this.filterModel = currentFilterData.filterData;
      this.dailyCapsGrid.setFilterModel(this.filterModel);
    }
    else {
      this.dailyCapsGrid.setFilterModel(null);
    }
  }

  isUserPreferenceChanged() {
    return this.dailyCapsGrid && this.dailyCapsGrid.isUserPreferenceChanged();
  }

  showResetUserPreferenceModal() {

    const title = " Reset Columns to the default/userPreference View";

    const data = {
      "message": "You are about to reset  Daily Caps  Columns to the default/userPreference View.",
      enablePreference: this.sharedService.disableRestButton ,
       action :  this.sharedService.disableRestButton ? 1: 0,
    };
    this.uiHelperService.askConfirmation(ResetPreferenceComponent, 'modal-lg', title, data, 'Cancel', 'Reset Preference', (data: any) => {
      this.resetUserPreference(data.data.action);
    });

  }

  resetUserPreference(action) {
    if(action ==0)
    {
      const request = {
        TabId: GridMaster.DailyCaps,

      };

      this.warehouselinesservice.ResetUserPreference(request).subscribe((response: any) => {
        let responseDetails = response.details;
        if (responseDetails.Response == "Success") {
          this.sharedService.disableRestButton  = false;
          this.uiHelperService.showSnackBar('Grid has been reset to default view successfully', SnackbarConfig.success);
          this.getUserPreference();
        }
      }, (error) => {
        this.uiHelperService.showSnackBar('Failed to reset user preference', SnackbarConfig.error);
      });
    }
    else{

      this.getUserPreference();

      this.uiHelperService.showSnackBar('Grid has been reset to saved user preference view successfully', SnackbarConfig.success);
    }

  }
}
