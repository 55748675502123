import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@ldsm/utils';
import { ConfigService, ApiConfig } from '@wlms-web/utils';

@Injectable({
  providedIn: 'root'
})
export class ExportHistoryService  extends BaseApiService {

  constructor(protected app: ConfigService, protected http: HttpClient) {
    super(
      http,
      app.getAppConfiguration().apiBaseUrl,
      app.getAppConfiguration().apiNamespace,
      ''
    );
  }

  getExportHistory(los, startDate, endDate) {
    return this.get('@fundingService/AllExportLoanForGrid?LoanOriginationSystem=' + los + '&startdate=' + startDate + '&enddate=' + endDate, ApiConfig.DISABLELOANCACHE);
  }
  getWarehouseline() {
    return this.get('WarehouseLine/GetWarehouseLineDetailsForLoanQueue', ApiConfig.DISABLEGETTIMECACHE);
  }
  assignAndExportLoan(request) {
    return this.post('Export/Empower/AssignandExport', request);
  }

  saveUserPreferences(request) {
    return this.post('@userServiceUrl/UserPreferencesForGrid', request);
  }

  getUserPreferences(userName, gridName) {
    return this.get('@userServiceUrl/UserPreferencesForGrid?UserName='+ userName + '&GridName=' + gridName, ApiConfig.DISABLELOANCACHE);
  }

  getColumnMaster(gridName) {
    return this.get('@userServiceUrl/ColumnsForGridPreferences?GridName=' + gridName);
  }

  ResetUserPreference(request) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };

    return this.delete('@userServiceUrl/UserPreferencesForGrid', options);
  }

  getFundedBatchDetails(batchNumber) {
    return this.get('@fundingService/FundedBatchDetails?BatchNumber=' + batchNumber, ApiConfig.DISABLELOANCACHE);
  }

  updateFundedBatchDetails(request) {
    return this.put('@fundingService/FundedBatchDetails', request);
  }
}
