import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { isNumber } from 'lodash';

@Pipe({
  name: 'customCurrency',
  standalone: true
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(
    value: any,
    currencyCode: string = 'USD',
    locale: string = 'en-US',
    digitsInfo: string = '1.2-2',
  ): any {
    return isNumber(value)
      ? formatCurrency(
          value,
          locale,
          getCurrencySymbol(currencyCode, 'wide'),
          currencyCode,
          digitsInfo
        )
      : value;
  }
}
