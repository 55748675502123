import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { WarehouseLinesService } from '../services/whl.service';
import { TimeAvailability } from '../config/table-structure-time-availability';
import {
  SharedService, NoRowsOverlayFlags, ScreenActionPrivileges, SnackbarConfig, WarehouseLineTabs,
  BrowserStorageService, AuthUtils
} from '@wlms-web/utils';
import { WarehouseLineCapacity } from '../models/whl-capacity.model';
import { Router } from '@angular/router';
import { UiHelperService, AlertModalComponent } from '@wlms-web/ui-kit';
import * as _ from 'lodash';
import { ManageTimeSlotComponent } from './manage-time-slot/manage-time-slot.component';
import { WarehouseLinesdetails } from '../config/table-structure-warehouselines';
import * as moment from 'moment';

@Component({
  selector: 'wlms-whl-summary',
  templateUrl: './whl-summary.component.html',
  styleUrls: ['./whl-summary.component.scss']
})

export class WarehouselineDetailGridviewComponent implements OnInit, AfterViewInit {
  subscription$: Subscription;
  wlCapacityList: any;
  rowData = [];
  columnDefs = [];
  allDataException = [];
  paginationPageSize = 50;
  tabList = [];
  selectedTab = 0;
  tileList: any = [];
  screenActionPrivileges = ScreenActionPrivileges;
  suppressActionPrivilege = false;
  suppressRightClickPrivilege = false;
  noRowsOverlayFlags = { ...NoRowsOverlayFlags };
  editType = '';
  @ViewChild('mainGrid') warehouseGrid: any;
  selectedIndices: any = [];
  indexList = [];
  editedWarehouseLines = [];
  timeAvailabltyList = [];
  timeSlots = [];
  @ViewChild('warehouseTab') warehouseTab: any;
  @ViewChild('dailyCapTab') dailyCapTab: any;
  @ViewChild('wetOverrideTab') wetOverrideTab: any;
  deletedRecords = [];
  isAdd = false;
  dummyId = -1;
  warehouseLines = [];
  cutOffCheck: boolean;
  ferderaltime: string;
  selected: boolean;

  constructor(private warehouselinesservice: WarehouseLinesService,
    public sharedService: SharedService,
    public authUtils: AuthUtils,
    private uiHelperService: UiHelperService,
    private cdRef: ChangeDetectorRef,
    private browserStorageService: BrowserStorageService,
    private router: Router) {
    this.wlCapacityList = new WarehouseLineCapacity();
  }

  ngOnInit(): void {
    this.sharedService.userPreferenceChanged = false;
    this.subscription$ = this.sharedService.tabChange.subscribe((response: any) => {
      if (response.isLabelDisplayed) {
        this.selectedIndices = [];
        this.editedWarehouseLines = [];
        this.deletedRecords = [];
        this.selectedTab = response.index;
        this.getCurrentTabData();
        this.setTabLevelPrivileges();
        this.sharedService.userPreferenceChanged = false;
        this.cdRef.detectChanges();
      }
    });
    this.getWarehouseline()
    this.tabList = [...WarehouseLineTabs].filter((x: any) => this.authUtils.doesUserAllowedToPerform(x.privilegeKey));
    this.setTabLevelPrivileges();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  getWarehouseline() {
    this.subscription$ = this.warehouselinesservice.GetWarehouseLineDetailsForLoanQueue().subscribe(response => {
      if (response) {
        this.sharedService.warehouseLines = [...[...response['details']].map(obj => ({
          warehouseLineId: obj.WarehouseLineId,
          name: obj.WarehouseLineName,
          icon: obj.Icon,
          code: obj.ShortCode,
          vendorId: obj.VendorId,
          fundingCutoff:obj.FundingCutoff,
          federalCutoff:obj.FederalCutoff
        })),
        { name: 'Unassigned', icon: 'icons_slash.svg', code: null, vendorId: 0 }];
      }
    });
  }

  setTabLevelPrivileges() {
    this.suppressRightClickPrivilege = !this.authUtils.doesUserAllowedToPerform(this.tabList[this.selectedTab].suppressRightClickPrivilege);
    this.suppressActionPrivilege = !this.authUtils.doesUserAllowedToPerform(this.tabList[this.selectedTab].suppressActionPrivilege);
    this.uiHelperService.numericValidatorType = this.tabList[this.selectedTab].numericValidatorType;
  }

  setCapacityTile(data) {
    this.tileList[0] = {
      label: 'Line Limit Amount',
      value: data.map(a => a.LineLimitAmount ? a.LineLimitAmount : 0).reduce((a, b) => { return parseFloat(a) + parseFloat(b); }),
      remainingPercentage: null
    };
    this.tileList[1] = {
      label: 'Amended Line Limit Amount',
      value: data.map(a => a.AmendedLineLimitAmount ? a.AmendedLineLimitAmount : 0).
        reduce((a, b) => { return parseFloat(a) + parseFloat(b); }),
      remainingPercentage: null
    };
    this.tileList[2] = {
      label: 'Funded Today',
      value: data.map(a => a.CommittedAmount ? a.CommittedAmount : 0).reduce((a, b) => { return parseFloat(a) + parseFloat(b); }),
      remainingPercentage: null
    };
    this.tileList[3] = {
      label: 'Uncommitted Amount',
      value: data.map(a => a.UncommittedAmount ? a.UncommittedAmount : 0).reduce((a, b) => { return parseFloat(a) + parseFloat(b); }),
      remainingPercentage: null
    };
    this.tileList[4] = {
      label: 'Outstanding Amount',
      value: data.map(a => a.OutStandingAmount ? a.OutStandingAmount : 0).
        reduce((a, b) => { return parseFloat(a) + parseFloat(b); }),
      remainingPercentage: null
    };
    this.tileList[5] = {
      label: 'Available Amount',
      value: data.map(a => a.AvailableAmount ? a.AvailableAmount : 0).reduce((a, b) => { return parseFloat(a) + parseFloat(b); }),
      remainingPercentage: null
    };

  }

  getCurrentTabData() {
    if (this.tabList[this.selectedTab].code == 'timeAvailability') {
      this.getTimeAvailability();
    }
  }

  getTimeAvailability() {
    this.rowData = [];
    this.sharedService.loadGrid.next({ isLoad: true });
    this.warehouselinesservice.getTimeAvailability().subscribe(response => {
      if (response) {
        this.timeAvailabltyList = [...response['details']];
        this.setEndDate(this.timeAvailabltyList);
        this.setTimeAvailabilityData();
        this.sharedService.loadGrid.next({ isLoad: false });
      }
    });
  }

  setEndDate(timedataresponse)
  {
    this.sharedService.dayEnd= moment.max(timedataresponse.map(x=> moment(new Date(new Date().getFullYear(),
    new Date().getMonth(), new Date().getDate(),
    parseInt(x.FederalCutoff.split(':')[0]),
    parseInt(x.FederalCutoff.split(':')[1]))))).format("HH:mm:ss");
  }

  setTimeAvailabilityData() {
    this.columnDefs = [...TimeAvailability];
    this.timeAvailabltyList.sort((a, b) => {
      return new Date(new Date().toDateString() + ' ' + a.FromTime).getTime() -
        new Date(new Date().toDateString() + ' ' + b.FromTime).getTime()
    });
    const timeList = this.timeAvailabltyList.filter((x: any) => x.IsDeleted != 1 && x.WarehouseLineTimeAvailabilityId > 0)
    const mainCols: any = _.groupBy(timeList, 'TimePeriodType');
    this.getTimeAvailabilityColumnDefs(mainCols, true);
    this.timeAvailabltyList = this.timeAvailabltyList.filter(x => x.TimePeriodTypeInd != 0);
    const warehouseEntries = [...Object.entries(_.groupBy(timeList, 'WarehouseLineId'))];
    const newWarehouseLines = this.timeAvailabltyList.filter((x: any) => x.WarehouseLineTimeAvailabilityId <= 0)
    const rows = this.getTimeAvailabilityRows(warehouseEntries);
    const newRows = this.getTimeAvailabilityRows(Object.entries(_.groupBy(newWarehouseLines, 'WarehouseLineId')));
    this.rowData = rows.concat(newRows);
    this.warehouseGrid.setRowData(this.rowData);
  }

  resetTimeAvailabilityData() {
    this.columnDefs = [...TimeAvailability];
    this.timeAvailabltyList.sort((a, b) => {
      return new Date(new Date().toDateString() + ' ' + a.FromTime).getTime() -
        new Date(new Date().toDateString() + ' ' + b.FromTime).getTime()
    });
    const timeList = this.timeAvailabltyList.filter((x: any) => x.IsDeleted != 1)
    const mainCols: any = _.groupBy(timeList, 'TimePeriodType');
    this.getTimeAvailabilityColumnDefs(mainCols, false);
    const warehouseEntries = [...Object.entries(_.groupBy(timeList, 'WarehouseLineId'))];
    this.rowData = [...this.getTimeAvailabilityRows(warehouseEntries)];
    this.warehouseGrid.setRowData(this.rowData);
  }

  getTimeAvailabilityColumnDefs(mainCols, isNew) {
    const timeSlotGrouped = { ...mainCols, ..._.groupBy(mainCols.Custom, 'FromTime') };
    delete timeSlotGrouped['Custom'];
    isNew ? this.seedTimingsForNewWarehouses(timeSlotGrouped) : '';
    const timeSlots = _.filter(this.columnDefs, (x: any) => {
      return x.colId == 'timeSlots'
    });
    _.forEach([...Object.keys(timeSlotGrouped)], (x: any) => {
      this.columnDefs.push({
        ...timeSlots[0], field: x, headerName: this.customiseHeader(x),
        hide: false, suppressFiltersToolPanel: false, suppressColumnsToolPanel: false,
        editable: this.authUtils.doesUserAllowedToPerform(ScreenActionPrivileges.EditTimeAvailabilityTab)
      });
    });
    if(this.columnDefs)
    {
      this.warehouseGrid.setColumnDefs(this.columnDefs);
    }

    this.warehouseGrid.reSizeToFit();
  }

  seedTimingsForNewWarehouses(timeSlotGrouped) {
    let newEntries = [];
    let dummyId = -1;
    const newWarehouseLines = this.timeAvailabltyList.filter((x: any) => x.WarehouseLineTimeAvailabilityId == 0);
    _.forEach(newWarehouseLines, wh => {
      newEntries = [];
      _.forEach(timeSlotGrouped, x => {
        wh = {
          ...wh, AvailabilityRanking: 0,
          FromTime: x[0].FromTime,
          EndTime: x[0].EndTime,
          IsDeleted: 0,
          TimePeriodType: x[0].TimePeriodType,
          TimePeriodTypeInd: x[0].TimePeriodTypeInd,
          WarehouseLineTimeAvailabilityId: dummyId,
          IsAdded: 1
        };
        newEntries.push(wh);
        dummyId--;
      });
      this.editedWarehouseLines = this.editedWarehouseLines.concat(newEntries);
      this.timeAvailabltyList = this.timeAvailabltyList.concat(newEntries);
    });
  }

  // Customise column headers in time availability grid
  customiseHeader(header) {
    if (header == 'PrefundPurch') {
      return 'Prefund Purchase Loan';
    } else if (header != 'Prefund') {
      const dt = new Date(new Date().toDateString() + ' ' + header)
      return ((dt.getHours() % 12) || 12) + ':' + (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes() + ' '
        + (dt.getHours() >= 12 ? 'pm PST' : 'am PST');
    } else {
      return header;
    }
  }

  // Set rows for time availability grid
  getTimeAvailabilityRows(warehouseEntries) {
    const rowValues = [];
    _.forEach(warehouseEntries, (x: any) => {
      let row = {
        WarehouseLineName: x[1][0].WarehouseLineName,
        WarehouseLineCode: x[1][0].WarehouseLineCode,
        WarehouseLineId: x[1][0].WarehouseLineId,
        FederalCutoff: x[1][0].FederalCutoff,
      }
      _.forEach(x[1], (y: any) => {
        y[y.FromTime] = 0
        row = {
          ...row, [y.FromTime]: y.AvailabilityRanking, [y.TimePeriodType]: y.AvailabilityRanking
        }
      });
      rowValues.push({ ...row });
    });
    return rowValues;
  }

  onCellEditStarted(event) {
    this.uiHelperService.acceptableList = [];
    if (this.tabList[this.selectedTab].code == 'timeAvailability') {
      this.uiHelperService.maxValueForNumericEditor = 9;
      this.uiHelperService.acceptableList = this.rowData.map((x: any) => x[event.column.colDef.field]);
      this.uiHelperService.acceptableList.splice(this.uiHelperService.acceptableList.findIndex((x: any) => x == event.value), 1);
    } else {
      this.uiHelperService.maxValueForNumericEditor = null;
    }
  }

  onCellEditStopped(event) {
    const rowNodes = this.warehouseGrid.gridApi.rowModel.rowsToDisplay;
    const isDuplicate = parseInt(event.value) > 0 ? this.uiHelperService.acceptableList.includes(parseInt(event.value)) : false;

    if (this.tabList[this.selectedTab].code == 'timeAvailability' && event.oldValue != event.newValue) {


      if(event?.data?.FederalCutoff && event.colDef.field != 'PrefundPurch' && event.colDef.field != 'Prefund')
      {
        this.cutOffCheck = false;
        this.selected = true;
        const selectTimeSlot = new Date(new Date().toDateString() + ' ' + event.colDef.field).getTime();
        const federalCutoff = new Date(new Date().toDateString() + ' ' + event.data.FederalCutoff).getTime();
        this.ferderaltime =  moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(),
                      parseInt(event.data.FederalCutoff.split(':')[0]),
                      parseInt(event.data.FederalCutoff.split(':')[1]))).format('hh:mm A');
        if(selectTimeSlot > federalCutoff && event.data[event.colDef.field] > 0)
        {
          this.cutOffCheck = true;
        }
        else{
          this.cutOffCheck = false;
        }
      }
      this.manageEditedDataForTimeAvailablity(event, isDuplicate,this.cutOffCheck);

      if(this.cutOffCheck)
      {
        if(event.oldValue ==0)
        {
          this.selected = false;
        }
      }
      rowNodes.forEach(node => {
        if (node.data && event.data.WarehouseLineId == node.data.WarehouseLineId && !isDuplicate && this.selected ) {
          !this.selectedIndices.includes(node.id) ? this.selectedIndices.push(node.id) : '';
        }
      });
    }
    this.warehouseGrid.setSelected();
    this.warehouseGrid.redrawRows();
  }

  manageEditedDataForTimeAvailablity(event, isDuplicate, cutoffcheck) {

    if(cutoffcheck )
    {
      event.data[event.colDef.field] = 0;
      this.uiHelperService.showSnackBar('Time availability periods must occur before the Federal Bank cutoff of '+ this.ferderaltime +' PST',
        SnackbarConfig.error);

    }
    else if (isDuplicate) {
      event.data[event.colDef.field] = event.oldValue;
      this.uiHelperService.showSnackBar('Reseting to previous value as the priority has already been assigned to a different warehouseline',
        SnackbarConfig.error);
    } else {
      const editedRecord = this.timeAvailabltyList.filter((x: any) => {
        if (event.colDef.field == 'PrefundPurch' || event.colDef.field == 'Prefund') {
          return x.TimePeriodType == event.colDef.field && x.WarehouseLineId == event.data.WarehouseLineId && x.IsDeleted == 0;
        } else {
          return x.FromTime == event.colDef.field && x.WarehouseLineId == event.data.WarehouseLineId &&
            x.TimePeriodType == 'Custom' && x.IsDeleted == 0;
        }
      });
      if (editedRecord.length > 0) {
        this.updateEditedRecordsToList(editedRecord, event);
      }
    }
  }

  updateEditedRecordsToList(editedRecord, event) {
    const index = this.editedWarehouseLines.findIndex((x: any) =>
      x.WarehouseLineTimeAvailabilityId == editedRecord[0].WarehouseLineTimeAvailabilityId &&
      x.TimePeriodTypeInd == editedRecord[0].TimePeriodTypeInd &&
      x.WarehouseLineId == editedRecord[0].WarehouseLineId);
    if (index != -1) {
      this.editedWarehouseLines[index] = {
        ...this.editedWarehouseLines[index],
        AvailabilityRanking: event.value, IsDeleted: 0
      };
    } else {
      this.editedWarehouseLines.push({ ...editedRecord[0], AvailabilityRanking: event.value })
    }
    const timeIndex = _.findIndex(this.timeAvailabltyList, (t) =>
      t.WarehouseLineTimeAvailabilityId == editedRecord[0].WarehouseLineTimeAvailabilityId &&
      t.TimePeriodTypeInd == editedRecord[0].TimePeriodTypeInd &&
      t.WarehouseLineId == editedRecord[0].WarehouseLineId);

    timeIndex != -1 ? this.timeAvailabltyList[timeIndex] = {
      ... this.timeAvailabltyList[timeIndex],
      AvailabilityRanking: event.value
    } : ''
  }

  updateWarehouses() {
    const slotsUpdated = this.timeAvailabltyList.filter((x: any) => x.IsDeleted || x.IsAdded || x.IsEdited);
    if (this.tabList[this.selectedTab].code == 'timeAvailability' &&
      ((this.selectedIndices && this.selectedIndices.length > 0) || (slotsUpdated && slotsUpdated.length > 0))) {
      this.saveTimeAvailablity(slotsUpdated);
    }
    else {
      this.uiHelperService.showSnackBar('Please edit warehouseline(s) in order to save', SnackbarConfig.error);
    }

  }

  // Prepare data for save time availablity
  prepareDataForTimeAvailabilitySave(slotsUpdated) {
    _.forEach(slotsUpdated, (s: any) => {
      const index = _.findIndex(this.editedWarehouseLines, (e: any) =>
        s.TimePeriodTypeInd == e.TimePeriodTypeInd &&
        s.WarehouseLineTimeAvailabilityId == e.WarehouseLineTimeAvailabilityId && s.WarehouseLineId == e.WarehouseLineId);
      if (index == -1) {
        this.editedWarehouseLines.push(s);
      } else if (s.IsDeleted) {
        this.editedWarehouseLines.splice(index, 1);
        this.editedWarehouseLines.push(s);
      }
      else {
        this.editedWarehouseLines[index].EndTime = s.EndTime;
        this.editedWarehouseLines[index].FromTime = s.FromTime;
        this.editedWarehouseLines[index].IsDeleted = s.IsDeleted;
      }
    });
    this.editedWarehouseLines.
      map(x => x.WarehouseLineTimeAvailabilityId < 0 ? x.WarehouseLineTimeAvailabilityId = 0 : x.WarehouseLineTimeAvailabilityId);
    this.editedWarehouseLines = this.editedWarehouseLines.filter(x => !(x.WarehouseLineTimeAvailabilityId == 0 && x.IsDeleted))
  }

  // Save time availability tab
  saveTimeAvailablity(slotsUpdated) {
    this.prepareDataForTimeAvailabilitySave(slotsUpdated);
    this.warehouselinesservice.editTimeAvailablity(this.editedWarehouseLines).
      subscribe(response => {
        if (response && response['details'] && response['details'].Status == 1) {
          if (slotsUpdated && slotsUpdated.length > 0) {
            this.uiHelperService.showSnackBar(`Successfully edited time availablity for ${this.selectedIndices.length}
          warehouseline(s) and updated timeslot(s).`, SnackbarConfig.success);
          } else {
            this.uiHelperService.showSnackBar(`Successfully edited time availablity for ${this.selectedIndices.length} warehouseline(s).`,
              SnackbarConfig.success);
          }
          this.selectedIndices = [];
          this.editedWarehouseLines = [];
          this.getCurrentTabData();
        }
      });
  }

  // Prepare input data for manage time slot pop up and call the method that opens the pop up
  manageTimeSlotModal() {
    this.warehouseGrid.stopEdit();
    this.timeSlots = [];
    const unDeletedTimes = _.filter(this.timeAvailabltyList, (x: any) => !x.IsDeleted);
    const mainCols: any = _.groupBy(unDeletedTimes, 'TimePeriodType');
    const timeSlotGrouped = _.groupBy(mainCols.Custom, 'FromTime');
    delete timeSlotGrouped['Custom'];
    _.forEach([...Object.values(timeSlotGrouped)], (e: any, i: any) => {
      this.timeSlots.push({
        timeId: i + 1,
        startTime: e[0].FromTime,
        endTime: e[0].EndTime,
        isAdd: false
      });
    });
    this.showManageTimeslotPopup();
  }

  // Show themanage time slot pop up
  showManageTimeslotPopup() {
    this.sharedService.modalSubmitValid = true;
    const title = 'Manage Time Slot';
    this.uiHelperService.askConfirmation(ManageTimeSlotComponent, 'modal-lg', title, this.timeSlots, 'Cancel', 'Apply', (data: any) => {
      _.forEach(data.data, (newSlots: any, newIndex: any) => {
        const index = _.findIndex(this.timeSlots, (slot: any) => {
          return slot.timeId == newSlots.timeId;
        });
        if (index != -1) {
          this.updateSlots(this.timeSlots[index], data.data[newIndex]);
        } else {
          this.addColumn(newSlots);
        }
      });
      this.removeSlots(data);
      this.timeAvailabltyList.sort((a, b) => {
        return new Date(new Date().toDateString() + ' ' + a.FromTime).getTime() -
          new Date(new Date().toDateString() + ' ' + b.FromTime).getTime()
      });
      this.resetTimeAvailabilityData();
      this.warehouseGrid.redrawRows();
      this.uiHelperService.showSnackBar('Successfully modified time slot(s)', SnackbarConfig.success);
    }, () => {
      this.resetTimeAvailabilityData();
    });
  }

  // Add a new column if new time slot is added
  addColumn(record) {
    const warehouseEntries = [...Object.entries(_.groupBy(this.timeAvailabltyList, 'WarehouseLineId'))];
    _.forEach(warehouseEntries, (x: any) => {
      this.timeAvailabltyList.push({
        AvailabilityRanking: 0,
        EndTime: record.endTime,
        FromTime: record.startTime,
        IsDeleted: 0,
        IsAdded: 1,
        TimePeriodType: 'Custom',
        TimePeriodTypeInd: 3,
        WarehouseLineName: x[1][0].WarehouseLineName,
        WarehouseLineCode: x[1][0].WarehouseLineCode,
        WarehouseLineId: x[1][0].WarehouseLineId,
        WarehouseLineTimeAvailabilityId: this.dummyId
      });
    });
    this.dummyId--;
  }

  // Remove a time slot
  removeSlots(data) {
    const removedData = [];
    _.forEach(this.timeSlots, (slot: any) => {
      if (data.data.findIndex((x: any) => x.startTime == slot.startTime && x.endTime == slot.endTime) == -1) {
        removedData.push(slot)
      }
    });
    _.forEach(removedData, (d: any) => {
      this.timeAvailabltyList.forEach((t: any, index: any) => {
        if ((t.FromTime == d.startTime &&
          t.EndTime == d.endTime && t.TimePeriodType == 'Custom')) {
          this.timeAvailabltyList[index] = { ...this.timeAvailabltyList[index], IsDeleted: 1 }
        }
      });
      this.editedWarehouseLines.forEach((t: any, index: any) => {
        if ((t.FromTime == d.startTime &&
          t.EndTime == d.endTime && t.TimePeriodType == 'Custom')) {
          this.editedWarehouseLines[index] = { ...this.editedWarehouseLines[index], IsDeleted: 1 }
        }
      });
    })
  }

  // Update existing slot
  updateSlots(oldSlot, newSlot) {
    _.forEach(this.timeAvailabltyList, (x: any, timeIndex: any) => {
      if (x.FromTime == oldSlot.startTime && x.EndTime == oldSlot.endTime) {
        this.timeAvailabltyList[timeIndex] = {
          ...this.timeAvailabltyList[timeIndex],
          FromTime: newSlot.startTime,
          EndTime: newSlot.endTime,
          IsEdited: 1
        };
      }
    });
  }

  // Open add warehouse pop up from warehouseLine component
  addWarehouse() {
    this.warehouseTab.openAddWarehouseLine();
  }

  // Update the list of edited rows based on row selection change
  onSelectionChanged(event) {
    event.forEach(node => {
      !this.selectedIndices.includes(node.id) ? this.selectedIndices.push(node.id) : '';
    });
    this.selectedIndices.forEach(index => {
      if (!event.map(x => x.id).includes(index)) {
        this.selectedIndices.splice(index, 1)
      }
    });
    if (this.tabList[this.selectedTab].code == 'timeAvailability') {
      this.editedWarehouseLines = this.editedWarehouseLines.filter(item1 =>
        event.some(item2 => (item2.data.WarehouseLineId == item1.WarehouseLineId))
      );
    }
  }

  noOverlay(event) {
    this.warehouseGrid.grid.api.setGridOption("noRowsOverlayComponentParams", {
      noRowsMessageFunc: function () {
        return {
          blankTemplateFlag: 'warehouses',
        };
      }
    });
  }

  showUserPreferenceModal() {
    this.sharedService.modalSubmitValid = true;
    const title = ' Save User Preferences';
    const data = {
      data: 'You are about to save User Preference.'
    };
    this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-md', title, data, 'Cancel', 'Save Preference', () => {
      if (this.tabList[this.selectedTab].code == 'warehouses') {
        this.warehouseTab.saveUserPreferences();
      }
      else if (this.tabList[this.selectedTab].code == 'dailycapAvailability') {
        this.dailyCapTab.saveUserPreferences();
      }
      else if (this.tabList[this.selectedTab].code == 'wetOverrides') {
        this.wetOverrideTab.saveUserPreferences();
      }
    });
  }

  showResetUserPreferenceModal()
  {
    this.sharedService.modalSubmitValid = true;

    if (this.tabList[this.selectedTab].code == 'warehouses') {
      this.warehouseTab.showResetUserPreferenceModal();
    }
    else if (this.tabList[this.selectedTab].code == 'dailycapAvailability') {
      this.dailyCapTab.showResetUserPreferenceModal();
    }
    else if (this.tabList[this.selectedTab].code == 'wetOverrides') {
      this.wetOverrideTab.showResetUserPreferenceModal();
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
