import { NgModule, APP_INITIALIZER, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { AppComponent } from './app.component';
import { CoreModule } from '@wlms-web/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppConfigLoader, ConfigService } from '@wlms-web/utils';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MSAL_INSTANCE, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalService, MsalBroadcastService, MsalGuard, MsalRedirectComponent, MsalModule, } from '@azure/msal-angular';
import { msalConfig, loginRequest } from './auth-config';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        TimepickerModule.forRoot(),
        ModalModule.forRoot(),
        AppRoutingModule,
        CoreModule,
        FormsModule,
        AgGridModule,
        BrowserAnimationsModule,
        FormsModule,
        MatProgressSpinnerModule,
        MsalModule], providers: [
        ConfigService,
        DatePipe,
        CurrencyPipe,
        {
            provide: APP_INITIALIZER,
            useFactory: AppConfigLoader,
            deps: [ConfigService],
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MsalGuardConfigurationFactory,
        },
        MsalService,
        MsalBroadcastService,
        MsalGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(private ngZone: NgZone) {
    (window as any).ngZone = this.ngZone;
  }
}
