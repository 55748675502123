<div class="app-l-banner-controls-wrap">
  <div
    class="btn-group"
    style="width: 100%; min-height: 30px; min-width: 126px; width: 190px"
    dropdown
    container="body"
    [class.disabled]="disableDropdown"
  >
    <button
      type="button"
      class="btn app-c-btn app-c-grid-action-btn-left"
      [disabled]="disableButton"
    >
      <label
        class="app-c-label"
        style="min-width: 105px; overflow: hidden; cursor: pointer"
      >
        {{ buttonLabel }}
      </label>
    </button>
    <button
      id="button-basic "
      dropdownToggle
      type="button"
      class="btn app-c-btn dropdown-toggle app-c-popdown-btn app-c-grid-action-btn-right"
      aria-controls="dropdown-basic"
      [class.disabled]="disableButton"
    ></button>
    <ul
      id="dropdown-basic "
      *dropdownMenu
      class="dropdown-menu popdown"
      role="menu"
      aria-labelledby="button-basic"
    >
      <li
        role="menuitem "
        class="app-l-list"
        style="
          border-bottom: 1px solid #f3eded;
          box-shadow: 8px 9px 15px -2px rgba(240, 234, 234, 0.7);
        "
        *ngFor="let button of buttonGroup"
        (click)="onOptionSelect(button)"
      >
        <a
          class="dropdown-item app-c-dropdown-item d-block active-dropdown"
          [ngClass]="button?.inRuleExcluded ? 'greyed-item' : ''"
        >
          <img *ngIf="button?.icon" [src]="button?.icon" class="image-item" />
          <label style="cursor: pointer; font-size: 14px">
            {{ button?.name }}
          </label>
        </a>
      </li>
    </ul>
  </div>
</div>
