import { AuthUtils } from './../auth/auth-utils';
import { Input, AfterViewInit, Directive, ViewContainerRef, TemplateRef } from '@angular/core';

@Directive({
  selector: '[wlmsIfPrivilege]',
  standalone: true
})
export class IfPrivilegeDirective implements AfterViewInit {
  @Input() public wlmsIfPrivilege: Array<string>;

  constructor(private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authUtils: AuthUtils) {
  }

  ngAfterViewInit(): void {
    if (this.authUtils.doesUserAllowedToPerform(this.wlmsIfPrivilege[0])) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
