import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModalComponent } from '@wlms-web/ui-kit';
import { BrowserStorageService, ExportHistory, NoRowsOverlayFlags, ScreenActionPrivileges, SharedService, SnackbarConfig, StorageKey, AuthUtils, LOSList } from '@wlms-web/utils';
import { ResetPreferenceComponent } from 'libs/ui-kit/src/lib/reset-preference/reset-preference.component';
import { UiHelperService } from 'libs/ui-kit/src/lib/services/ui-helper.service';
import { ExportRequestAction, GridMaster } from 'libs/utils/src/lib/constants/data-constants';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { CsvGeneratorModalComponent } from './csv-generator-modal/csv-generator-modal/csv-generator-modal.component';
import { CsvGeneratorResultModalComponent } from './csv-generator-result-modal/csv-generator-result-modal/csv-generator-result-modal.component';
import { FundingBatchDetailModalComponent } from './funding-batch-detail-modal/funding-batch-detail-modal.component';
import { ExportHistoryService } from './services/export-history.service';

@Component({
  selector: 'wlms-web-export-history',
  templateUrl: './export-history.component.html',
  styleUrls: ['./export-history.component.scss']
})
export class ExportHistoryComponent implements OnInit, AfterViewInit {

  paginationPageSize = 50;
  showspinner = false;
  rowData = [];
  columnDefs = [...ExportHistory];
  allData = [];
  subscription$: Subscription;
  selectedLoan: any;
  selectedTab = 0;
  blankTemplate: any = '';
  selectedLoans: any = [];
  userDetails: any;
  isRefresh = false;
  selectedIndices = [];
  @ViewChild('exporthistoryGrid') exporthistoryGrid: any;
  disablePreference = true;
  preferance: any[] = [];
  columnMaster = [];
  selectedTabPreference: any[] = [];
  disableResetPreference = false;
  screenActionPrivileges = ScreenActionPrivileges;
  suppressActionPrivilege = false;
  suppressRightClickPrivilege = false;
  noRowsOverlayFlags: any = { ...NoRowsOverlayFlags };
  filterPreferance: any[] = [];
  filterModel: any;
  isGridReloaded = false;
  warehouseLineName: string;
  losList = [...LOSList];
  losSelected: any;

  // defaulting to 1 week back
  startDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);;
  endDate = new Date();
  bsConfig = { containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' };
  constructor(private exportHistoryService: ExportHistoryService,
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    private uiHelperService: UiHelperService,
    private browserStorageService: BrowserStorageService,
    private authUtils: AuthUtils,
    public router: Router) {
    this.suppressRightClickPrivilege = !this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.ExportHistoryRightMouseClick);
    this.suppressActionPrivilege = !this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.ExportHistoryGridAction);
    this.losSelected = this.losList[0];
  }


  ngOnInit(): void {
    this.isRefresh = false;
    this.sharedService.modalSubmitValid = true;
    this.userDetails = this.authUtils.getLoggedInUserDetails();

    this.getColumnMaster();
    this.disableResetPreference = false;
    this.getExportHistory();

    this.getUserPreference();
  }


  rowSelectionEvent(event) {
    this.selectedLoans = event;
  }

  onfilterChanged(event) {
    if (!this.isGridReloaded) {
      this.filterModel = event;
      this.filterPreferance = [];
      const getFilterPreference = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
      this.filterPreferance = getFilterPreference ? getFilterPreference : [];
      const idx = this.filterPreferance.findIndex((x: any) => x.tabName == GridMaster.ExportHistory);
      if (idx >= 0) {
        this.filterPreferance[idx] = { ...this.filterPreferance[idx], filterData: this.filterModel }
      }
      else {
        this.filterPreferance.push({
          tabName: GridMaster.ExportHistory,
          filterData: this.filterModel
        });
      }
      this.browserStorageService.setLocalStorageValue(StorageKey.FILTERMODEL, this.filterPreferance);
    }
  }
  onIsRowSelectable(rowNode) {
    return rowNode.data ? !rowNode.data.IsLoanExported : false;
  }
  noOverlay(event) {
    this.exporthistoryGrid.grid.noRowsOverlayComponentParams = {
      noRowsMessageFunc: function () {
        return {
          blankTemplateFlag: 'isLoanAssignments',
        };
      }
    };
  }

  viewFundingFile(event){
    const data = {
      batchNumber: event.data.batchNumber,
      warehouseLineName : event.data.warehouseLine
    };

    const title = 'Funding Batch Details :: ' + event.data.warehouseLine + ' :: ' + event.data.batchNumber;

    this.uiHelperService.askConfirmation(FundingBatchDetailModalComponent, 'modal-xl', title, data, 'Cancel', '', (result: any) => {
      this.showspinner = true;

      this.showspinner = false;
    });
  }

  getExportHistory() {
    this.sharedService.loadGrid.next({ isLoad: true });
    this.subscription$ = this.exportHistoryService.getExportHistory(this.losSelected.name, this.startDate.toLocaleDateString('en-US'), this.endDate.toLocaleDateString('en-US')).subscribe(response => {
      if (response) {
        this.rowData = response['details'];
        this.sharedService.loadGrid.next({ isLoad: false });
      }
    });
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
  showGenerateDataTapeModal() {
    const data = {
      isEdit: false,
      loanNumber: '',
      warehouseLineId: '',
      warehouseLineName: '',
      skipValidation: false,
      skipEmpowerUpdates: false,
      excludeCustodianFile: false,
      exportRequestAction: ExportRequestAction
    };
    const title = 'Generate DataTape';

    this.uiHelperService.askConfirmation(CsvGeneratorModalComponent, 'modal-lg', title, data, 'Cancel', 'Generate', (result: any) => {
      this.showspinner = true;

      const loannumbers = data.loanNumber.split(",").filter(e => e != "");
      const request = {
        LoanNumbers: loannumbers,
        WarehouseLineId: data.warehouseLineId,
        WarhouseLineName: data.warehouseLineName,
        SkipValidation: data.skipValidation,
        SkipEmpowerUpdates: data.skipEmpowerUpdates,
        ExcludeCustodianFile: data.excludeCustodianFile,
        ExportRequestAction: data.exportRequestAction
      }
      this.warehouseLineName = data.warehouseLineName;
      this.exportHistoryService.assignAndExportLoan(request).subscribe(response => {
        const result = response['details'];
        if (result && result.LoanCsvData) {
          setTimeout(() => {
            const data = {
              "data": result.LoanCsvData,
              "WarehouseLine": this.warehouseLineName
            };
            this.uiHelperService.askConfirmation(CsvGeneratorResultModalComponent, 'modal-lg', `Export Status - ${this.warehouseLineName}`,
              data, '', 'Ok', () => {
              });
            this.showspinner = false;
          }, 50);
        }
        else {
          this.uiHelperService.showSnackBar('Failed to Generate DataTape', SnackbarConfig.error);
          this.showspinner = false;
        }
      });
    });
  }

  showUserPreferenceModal() {
    this.sharedService.modalSubmitValid = true;
    const title = ' Save User Preferences';
    const data = {
      data: 'You are about to save User Preference.'
    };
    this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-md', title, data, 'Cancel', 'Save Preference', () => {

        this.saveUserPreferences();

    });
  }

  saveUserPreferences() {
    this.reorderColumnMaster();
    this.collectUserPreferences();
    this.selectedTabPreference = [];
    this.selectedTabPreference.push({"tabName": GridMaster.ExportHistory});

    const request = {
      userName: this.userDetails?.email,
      gridName: GridMaster.ExportHistory,
      columnPreferencesList: this.preferance
    };

    this.exportHistoryService.saveUserPreferences(request).subscribe((response: any) => {
      if (response) {
        this.sharedService.userPreferenceChanged = false;
        this.uiHelperService.showSnackBar('User preferences saved successfully', SnackbarConfig.success);
        this.getUserPreference();
        this.disableResetPreference =  true;

      }
    }, (error) => {
      this.uiHelperService.showSnackBar('Failed to save user preference', SnackbarConfig.error);
    });
  }

  getColumnMaster() {
    this.exportHistoryService.getColumnMaster(GridMaster.ExportHistory).subscribe((response: any) => {
      if (response) {
        this.columnMaster = response.details;
        this.reorderColumnMaster();
      }
    });
  }

  reorderColumnMaster() {
    _.forEach(this.columnDefs, (col: any, index: number) => {
      const masterIndex = _.findIndex(this.columnMaster, (master: any) => {
        return master.columnName?.toLowerCase() == col.field?.toLowerCase();
      });
      if (masterIndex != -1) {
        this.columnDefs[index]['ColumnMasterId'] = this.columnMaster[masterIndex].columnMasterId;
      }
    });
  }

  collectUserPreferences() {
    this.preferance = [];
    const columnState = this.exporthistoryGrid.getColumnState();
    _.forEach(columnState, (state: any, index: number) => {
      let columnMasterId = null;
      const columnIndex = _.findIndex(this.columnDefs, (col) => {
        return col.field == state.colId;
      });
      if (columnIndex != -1) {
        const col = { ...this.columnDefs[columnIndex] };
        columnMasterId = col['ColumnMasterId'];
        if (columnMasterId > 0) {
          this.preferance.push({
            tabName: GridMaster.ExportHistory,
            columnMasterId: columnMasterId,
            columnOrder: index,
            sortOrder: state.sort,
            groupOrder: (state.rowGroupIndex != null ? state.rowGroupIndex + 1 : state.rowGroupIndex) ?? 0,
            isVisible: !state.hide
          });
        }
      }
    });
  }

  getUserPreference() {
    if (this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.GetExportHistorySavePreference)) {
      this.exportHistoryService.getUserPreferences(this.userDetails?.email, GridMaster.ExportHistory).subscribe(response => {
        this.persistFilterData();
        if (response) {
          this.preferance = response.details;
          this.disableResetPreference = this.preferance.map((pref) => pref.tabName).includes(GridMaster.ExportHistory);
          this.setPreference()
        }
      });
    }
  }

  setPreference() {
    this.reorderColumnMaster();
    const tabPreference = _.filter(this.preferance, (pref: any) => {
      return pref.tabName == GridMaster.ExportHistory;
    });
    if (tabPreference && tabPreference.length > 0) {
      _.forEach(this.columnDefs, (col: any, index: number) => {
        const prefColumn = _.filter(tabPreference, (pref: any) => {
          return pref.columnMasterId == col.ColumnMasterId;
        });
        const column = { ...this.columnDefs[index] };
        this.columnDefs[index] = this.updateColumnProps(prefColumn, column);
      });
      const sortedCols = _.sortBy(this.columnDefs, (col: any) => {
        return col['ColumnOrder'];
      });
      this.columnDefs = [...sortedCols];
    } else {
      this.columnDefs = [...ExportHistory];
    }
  }

  updateColumnProps(prefColumn, column) {
    if (prefColumn && prefColumn.length > 0) {
      column['sort'] = prefColumn[0].sortOrder;
      column['ColumnOrder'] = prefColumn[0].columnOrder;
      column['hide'] = !prefColumn[0].isVisible;
      if (prefColumn[0].groupOrder > 0) {
        column['rowGroup'] = true;
        column['rowGroupIndex'] = prefColumn[0].groupOrder - 1;
      } else {
        column['rowGroup'] = false;
      }
    }
    return column;
  }

  persistFilterData() {
    this.filterPreferance = [];
    this.filterModel = [];
    this.filterPreferance = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
    const currentFilterData = this.filterPreferance ? this.filterPreferance.filter(x => x.tabName === GridMaster.ExportHistory)[0] : null;
    if (currentFilterData) {
      this.filterModel = currentFilterData.filterData;
      this.exporthistoryGrid.setFilterModel(this.filterModel);
    }
    else {
      this.exporthistoryGrid.setFilterModel(null);
    }
  }

  isUserPreferenceChanged() {
    return this.exporthistoryGrid && this.exporthistoryGrid.isUserPreferenceChanged();
  }


  showResetUserPreferenceModal() {

    const title = " Reset Columns to the default/userPreference View";

    const data = {
      "message": "You are about to reset  Export History Columns to the default/userPreference View.",
      enablePreference: this.disableResetPreference,
       action :  this.disableResetPreference ? 1: 0,
    };
    this.uiHelperService.askConfirmation(ResetPreferenceComponent, 'modal-lg', title, data, 'Cancel', 'Reset Preference', (data: any) => {
      this.resetUserPreference(data.data.action);
    });

  }

  resetUserPreference(action) {
    this.sharedService.userPreferenceChanged = false;
    if(action ==0)
    {
      const request = {
        userName: this.userDetails?.email,
        gridName: GridMaster.ExportHistory,
        tabName: GridMaster.ExportHistory
      };

      this.exportHistoryService.ResetUserPreference(request).subscribe((response: any) => {
        const responseDetails = response.details;
        if (responseDetails.status) {

          this.disableResetPreference =  false;

          this.uiHelperService.showSnackBar('Grid has been reset to default view successfully', SnackbarConfig.success);
          this.getUserPreference();
        }
      }, (error) => {
        this.uiHelperService.showSnackBar('Failed to reset user preference', SnackbarConfig.error);
      });
    }
    else{

      this.getUserPreference();

      this.uiHelperService.showSnackBar('Grid has been reset to saved user preference view successfully', SnackbarConfig.success);
    }

  }

  onLosSelected(event) {
    this.losSelected = event;
    this.getExportHistory();
  }

  onStartEndDatesChange() {
    this.getExportHistory();
  }
}

