import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationConfig, SnackbarConfig, SharedService } from '@wlms-web/utils';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoanAssignmentService } from '../../services/loan-assignment.service';
import { UiHelperService } from '@wlms-web/ui-kit';
import { Router } from '@angular/router';
import { LOSList } from '@wlms-web/utils';

@Component({
  selector: 'wlms-loanlookup-exception',
  templateUrl: './loanlookup-exception.component.html',
  styleUrls: ['./loanlookup-exception.component.scss']
})
export class LoanlookupExceptionComponent implements OnInit {

  public addExceptionForm: UntypedFormGroup;
  loanNumber: string ='';
  fieldLength: string;
  validationPattern : string;
  errorMessage: string;
  searchResults: any;
  addExceptionFormValue: any;
  showErrorMessage = false;
  losList = [...LOSList];
  losSelected: any;
  @Input() data: any;

  
  constructor(private modalService: BsModalService,
    private addExceptionService: LoanAssignmentService,
    private sharedService: SharedService,
    private uiHelperService: UiHelperService,
    public router: Router)
  {
    this.losSelected = this.losList[0];
  }

  ngOnInit(): void {
    this.fieldLength = ValidationConfig.LoanNumberFieldLength;
    this.validationPattern = ValidationConfig.AllowOnlyNumeric;
    this.addExceptionForm = new UntypedFormGroup({
      loanNumber: new UntypedFormControl()
    }); 
    this.sharedService.modalSubmitValid =false;
    this.losSelected = this.data.losSelected;
  }

  onLosSelected(event) {
    this.losSelected = event;
    this.searchResults = '';
    this.errorMessage = '';
    this.showErrorMessage = false;
  }

  loanlookUp(addExceptionFormValue)  {
    if (addExceptionFormValue.loanNumber)
    {

      if (this.addExceptionForm.valid)
      {
        this.loanNumber = addExceptionFormValue.loanNumber;
        this.executegetSearchResults(this.loanNumber);
      }
      else
      {
        this.showErrorMessage = true;
      }
    }
    else
    {
      this.showErrorMessage = true;
    }
  }

  executegetSearchResults(searchFormValue) {
    this.sharedService.modalSubmitValid =false;
    this.data.loanNumber = searchFormValue;
    this.data.losSelected = this.losSelected;
    this.addExceptionService.searchLoan(searchFormValue, this.losSelected.name, this.data.testMode).subscribe(
      (response: any) => {
        if (response?.details?.response.length > 0)
        {
          this.searchResults = response?.details?.response.join('<br/>').replace(/\n/g, '<br/>');
          let loandata = this.sharedService.loanData;
          let hasLoan = loandata.find(x=>x.loanNumber ==this.loanNumber);

          if (!hasLoan) {
            this.updateExceptionProperties(response?.details?.addException, response?.details?.deleteException);
          }
          else {
            this.updateExceptionProperties(false, response?.details?.deleteException);
            this.searchResults += '<br/>The loan number is already displayed in the Grid.';
          }
        }
        else
        {
          this.errorMessage = 'No response details from searching ' + searchFormValue;
        }
      },
      (error) => {
        if (error?.error?.outcomes?.messages.length > 0)
        {
          this.errorMessage = error?.error?.outcomes?.messages;
        }
        else
        {
          this.errorMessage = error?.message;
        }
      }
    );
  }

  clearMessage(addExceptionFormValue) {
    this.updateExceptionProperties(false, false);
    if (!addExceptionFormValue.searchText) {
      this.searchResults = '';
      this.errorMessage = '';
      this.showErrorMessage = false;
    }
    else {
      this.searchResults = '';
    }
  }

  closeModal() {
    this.modalService.hide();
  }

  updateExceptionProperties(isAddException, isDeleteException) {
    this.data.addException = isAddException;
    if (isAddException) {
      this.uiHelperService.updateSuccessButtonText('Add Exception');
      this.sharedService.modalSubmitValid = true;
    }
    else if (isDeleteException) {
      this.uiHelperService.updateSuccessButtonText('Delete Exception')
      this.sharedService.modalSubmitValid = true;
    }
    else {
      this.sharedService.modalSubmitValid = false;
      this.uiHelperService.updateSuccessButtonText('Add Exception');
    }
  }
}
