import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ReportService } from '../services/report.service';
import { NoRowsOverlayFlags, SharedService } from '@wlms-web/utils';
import * as Papa from 'papaparse'

@Component({
  selector: 'wlms-web-view-report-modal-component',
  templateUrl: './view-report-modal-component.component.html',
  styleUrls: ['./view-report-modal-component.component.scss']
})
export class ViewReportModalComponentComponent implements OnInit {
  @Input() data: any;
  @ViewChild('viewReportGrid') viewReportGrid: any;

  showspinner = false;
  columnDefs = [];
  rowData = [];
  noRowsOverlayFlags: any = { ...NoRowsOverlayFlags };

  constructor(
    private sharedService: SharedService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.getReportData();
  }

  getReportData() {
    this.showspinner = true;
    this.sharedService.loadGrid.next({ isLoad: true });
    this.reportService.getReport(this.data.containerName, this.data.fileName).subscribe(response => {
      const reportDetails = response['details']['jobReports'][0]['fileContent'];

      if (reportDetails) {
        const jsonResponse = Papa.parse(reportDetails, { header: true });
        const reportJSON = jsonResponse.data;

        const colDefsForReport = [];
        const keys = Object.keys(reportJSON[0]);
        keys.forEach(key => colDefsForReport.push({ field: key, filter: 'agTextColumnFilter' }));

        this.columnDefs = colDefsForReport;
        this.rowData = reportJSON;
      }
      else {
        this.columnDefs = [];
        this.rowData = [];
      }

      this.sharedService.loadGrid.next({ isLoad: false });
      this.showspinner = false;
    });
  }

  noOverlay(event) {
    this.viewReportGrid.grid.api.setGridOption("noRowsOverlayComponentParams", {
      noRowsMessageFunc: function () {
        return {
          blankTemplateFlag: NoRowsOverlayFlags.NORECORDS,
        };
      }
    });
  }
}
