import { Component, OnInit, ViewChild } from '@angular/core';
import { NoRowsOverlayFlags, BrowserStorageService, StorageKey, SharedService, ScreenActionPrivileges, AuthUtils, SnackbarConfig } from '@wlms-web/utils';
import { ReportGridCols } from '../config/table-structure-reports';
import { GridMaster } from 'libs/utils/src/lib/constants/data-constants';
import { ReportService } from '../services/report.service';
import { UiHelperService, AlertModalComponent } from '@wlms-web/ui-kit';
import { ResetPreferenceComponent } from 'libs/ui-kit/src/lib/reset-preference/reset-preference.component';
import { ViewReportModalComponentComponent } from '../view-report-modal-component/view-report-modal-component.component';
import * as _ from 'lodash';

@Component({
  selector: 'wlms-web-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  @ViewChild('reportsGrid') reportsGrid: any;

  screenActionPrivileges = ScreenActionPrivileges;
  selectedTabPreference: any[] = [];
  userDetails: any;
  preferance: any[] = [];
  disableResetPreference = false;
  columnMaster = [];

  showspinner = false;
  isRefresh = false;
  paginationPageSize = 50;
  noRowsOverlayFlags: any = { ...NoRowsOverlayFlags };
  suppressRightClickPrivilege = false;
  suppressActionPrivilege = false;
  columnDefs = [...ReportGridCols];
  rowData = [];

  isGridReloaded = false;
  filterModel: any;
  filterPreferance: any[] = [];

  bsConfig = { containerClass: 'theme-dark-blue', showClearButton: true, clearPosition: 'right' };
  vendor = '';
  reportType = '';
  // defaulting to 1 week back
  startDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);;
  endDate = new Date();

  constructor(
    private reportService: ReportService,
    private sharedService: SharedService,
    private uiHelperService: UiHelperService,
    private authUtils: AuthUtils,
    private browserStorageService: BrowserStorageService
  ) { }

  ngOnInit(): void {
    this.isRefresh = false;
    this.sharedService.modalSubmitValid = true;
    this.userDetails = this.authUtils.getLoggedInUserDetails();

    this.getColumnMaster();
    this.disableResetPreference = false;
    this.getReports();

    this.getUserPreference();
  }

  getReports() {
    this.sharedService.loadGrid.next({ isLoad: true });
    this.reportService.getJobReports(this.vendor,
      this.reportType,
      this.startDate.toLocaleDateString('en-US'),
      this.endDate.toLocaleDateString('en-US')).subscribe(response => {
      if (response) {
        this.rowData = response['details']['jobReports'];
        this.sharedService.loadGrid.next({ isLoad: false });
      }
    });
  }

  noOverlay(event: any) {
    this.reportsGrid.grid.api.setGridOption("noRowsOverlayComponentParams", {
      noRowsMessageFunc: function () {
        return {
          blankTemplateFlag: 'reports',
        };
      }
    });
  }

  onFilterClick(){
    this.getReports();
  }

  viewReport(event){
    const data = {
      containerName: event.data.containerName,
      fileName : event.data.fileName
    };

    const title = 'View Report - ' + event.data.fileName;

    this.uiHelperService.askConfirmation(ViewReportModalComponentComponent, 'modal-xl', title, data, 'Cancel', '', (result: any) => {
      this.showspinner = true;

      this.showspinner = false;
    });
  }

  showUserPreferenceModal() {
    this.sharedService.modalSubmitValid = true;
    const title = ' Save User Preferences';
    const data = {
      data: 'You are about to save User Preference.'
    };
    this.uiHelperService.askConfirmation(AlertModalComponent, 'modal-md', title, data, 'Cancel', 'Save Preference', () => {
      this.saveUserPreferences();
    });
  }

  showResetUserPreferenceModal() {
    const title = " Reset Columns to the default/userPreference View";
    const data = {
      "message": "You are about to reset  Reports Columns to the default/userPreference View.",
      enablePreference: this.disableResetPreference,
      action: this.disableResetPreference ? 1 : 0,
    };
    this.uiHelperService.askConfirmation(ResetPreferenceComponent, 'modal-lg', title, data, 'Cancel', 'Reset Preference', (data: any) => {
      this.resetUserPreference(data.data.action);
    });
  }

  resetUserPreference(action) {
    this.sharedService.userPreferenceChanged = false;
    if (action == 0) {
      const request = {
        userName: this.userDetails?.email,
        gridName: GridMaster.Reports,
        tabName: GridMaster.Reports
      };

      this.reportService.ResetUserPreference(request).subscribe((response: any) => {
        const responseDetails = response.details;
        if (responseDetails.status) {
          this.disableResetPreference = false;
          this.uiHelperService.showSnackBar('Grid has been reset to default view successfully', SnackbarConfig.success);
          this.getUserPreference();
        }
      }, (error) => {
        this.uiHelperService.showSnackBar('Failed to reset user preference', SnackbarConfig.error);
      });
    }
    else {

      this.getUserPreference();
      this.uiHelperService.showSnackBar('Grid has been reset to saved user preference view successfully', SnackbarConfig.success);
    }
  }

  onfilterChanged(event: any) {
    if (!this.isGridReloaded) {
      this.filterModel = event;
      this.filterPreferance = [];
      const getFilterPreference = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
      this.filterPreferance = getFilterPreference ? getFilterPreference : [];
      const idx = this.filterPreferance.findIndex((x: any) => x.tabName == GridMaster.Reports);
      if (idx >= 0) {
        this.filterPreferance[idx] = { ...this.filterPreferance[idx], filterData: this.filterModel }
      }
      else {
        this.filterPreferance.push({
          tabName: GridMaster.Reports,
          filterData: this.filterModel
        });
      }
      this.browserStorageService.setLocalStorageValue(StorageKey.FILTERMODEL, this.filterPreferance);
    }
  }

  getColumnMaster() {
    this.reportService.getColumnMaster(GridMaster.Reports).subscribe((response: any) => {
      if (response) {
        this.columnMaster = response.details;
        this.reorderColumnMaster();
      }
    });
  }

  getUserPreference() {
    if (this.authUtils.doesUserAllowedToPerform(this.screenActionPrivileges.GetReportsSavePreference)) {
      this.reportService.getUserPreferences(this.userDetails?.email, GridMaster.Reports).subscribe(response => {
        this.persistFilterData();
        if (response) {
          this.preferance = response.details;
          this.disableResetPreference = this.preferance.map((pref) => pref.tabName).includes(GridMaster.Reports);
          this.setPreference()
        }
      });
    }
  }

  setPreference() {
    this.reorderColumnMaster();
    const tabPreference = _.filter(this.preferance, (pref: any) => {
      return pref.tabName == GridMaster.Reports;
    });
    if (tabPreference && tabPreference.length > 0) {
      _.forEach(this.columnDefs, (col: any, index: number) => {
        const prefColumn = _.filter(tabPreference, (pref: any) => {
          return pref.columnMasterId == col.ColumnMasterId;
        });
        const column = { ...this.columnDefs[index] };
        this.columnDefs[index] = this.updateColumnProps(prefColumn, column);
      });
      const sortedCols = _.sortBy(this.columnDefs, (col: any) => {
        return col['ColumnOrder'];
      });
      this.columnDefs = [...sortedCols];
    } else {
      this.columnDefs = [...ReportGridCols];
    }
  }

  saveUserPreferences() {
    this.reorderColumnMaster();
    this.collectUserPreferences();
    this.selectedTabPreference = [];
    this.selectedTabPreference.push({ "tabName": GridMaster.Reports });

    const request = {
      userName: this.userDetails?.email,
      gridName: GridMaster.Reports,
      columnPreferencesList: this.preferance
    };

    this.reportService.saveUserPreferences(request).subscribe((response: any) => {
      if (response) {
        this.sharedService.userPreferenceChanged = false;
        this.uiHelperService.showSnackBar('User preferences saved successfully', SnackbarConfig.success);
        this.getUserPreference();
        this.disableResetPreference = true;

      }
    }, (error) => {
      this.uiHelperService.showSnackBar('Failed to save user preference', SnackbarConfig.error);
    });
  }

  reorderColumnMaster() {
    _.forEach(this.columnDefs, (col: any, index: number) => {
      const masterIndex = _.findIndex(this.columnMaster, (master: any) => {
        return master.columnName?.toLowerCase() == col.field?.toLowerCase();
      });
      if (masterIndex != -1) {
        this.columnDefs[index]['ColumnMasterId'] = this.columnMaster[masterIndex].columnMasterId;
      }
    });
  }

  collectUserPreferences() {
    this.preferance = [];
    const columnState = this.reportsGrid.getColumnState();
    _.forEach(columnState, (state: any, index: number) => {
      let columnMasterId = null;
      const columnIndex = _.findIndex(this.columnDefs, (col) => {
        return col.field == state.colId;
      });
      if (columnIndex != -1) {
        const col = { ...this.columnDefs[columnIndex] };
        columnMasterId = col['ColumnMasterId'];
        if (columnMasterId > 0) {
          this.preferance.push({
            tabName: GridMaster.Reports,
            columnMasterId: columnMasterId,
            columnOrder: index,
            sortOrder: state.sort,
            groupOrder: (state.rowGroupIndex != null ? state.rowGroupIndex + 1 : state.rowGroupIndex) ?? 0,
            isVisible: !state.hide
          });
        }
      }
    });
  }

  updateColumnProps(prefColumn, column) {
    if (prefColumn && prefColumn.length > 0) {
      column['sort'] = prefColumn[0].sortOrder;
      column['ColumnOrder'] = prefColumn[0].columnOrder;
      column['hide'] = !prefColumn[0].isVisible;
      if (prefColumn[0].groupOrder > 0) {
        column['rowGroup'] = true;
        column['rowGroupIndex'] = prefColumn[0].groupOrder - 1;
      } else {
        column['rowGroup'] = false;
      }
    }
    return column;
  }

  persistFilterData() {
    this.filterPreferance = [];
    this.filterModel = [];
    this.filterPreferance = this.browserStorageService.getLocalStorageValue(StorageKey.FILTERMODEL);
    const currentFilterData = this.filterPreferance ? this.filterPreferance.filter(x => x.tabName === GridMaster.Reports)[0] : null;
    if (currentFilterData) {
      this.filterModel = currentFilterData.filterData;
      this.reportsGrid.setFilterModel(this.filterModel);
    }
    else {
      this.reportsGrid.setFilterModel(null);
    }
  }
}
