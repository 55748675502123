import { CustomSorting, CellClassRules, CurrencyFormatter, PercentageFormatter,DateFormattor,ConditionalEditor } from '../gridutils/grid-utils';
export const Loandetails = [
  {
    'headerName': 'Color',
    field: 'color',
    cellRenderer: 'colorCellRenderer',
    width: 12,
    minWidth: 5,
    cellClass: 'color-cell',
    resizable: false,
    lockPosition: true,
    pinned: 'left',
    filter: 'agSetColumnFilter',
    "suppressHeaderMenuButton": true,
  },

  {
    'headerName': '',
    "field": "rowCheck",
    "checkboxSelection": true,
    "headerCheckboxSelection": true,
    "headerCheckboxSelectionFilteredOnly" : true,
    "active": true,
    "width": 40,
    "headerClass": "no-left",
    cellStyle: { left: '0px', 'z-index':'0' },
    cellClass: params => {
      return params.data ? 'wlms-checkbox-' + params.data.loanNumber : '';
    },
    "pinned": "left",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressHeaderMenuButton": true,
    lockPosition: true,
  },
  {
    "field": "channel",
    "headerName": "Channel",
    "width": 165,
    "filter": "agSetColumnFilter",
    pinned: 'left',
  },
  {
    "field": "loanNumber",
    "headerName": "Loan Number",
    "width": 150,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
    pinned: 'left',
  },
  {
    "field": "LoanExported",
    "headerName": "Loan DataTape Exported",
    "width": 120,
    "filter": "agSetColumnFilter",
    hide:true,
    filterParams:{
      'values': ['YES','NO']
    },
    "suppressFiltersToolPanel": false,
    "suppressColumnsToolPanel": true,
  },
  {
    "field": "LoanUpdateStatus",
    "headerName": "Loan Updates Status after Export",
    "width": 120,
    "filter": "agSetColumnFilter",
    hide:true,
    "suppressFiltersToolPanel": false,
    "suppressColumnsToolPanel": true,
  },
  {
    "field": "lastName",
    "headerName": "Last Name",
    "width": 150,
    "filter": "agTextColumnFilter",
    detailCategory: 2,
    pinned: 'left',
  },

  {
    "field": "sentToFundingDate",
    "headerName": "Sent To Treasury",
    "width": 180,
    detailValueFormatters: ['dateTime'],
    detailCategory: 3,
    "filter": "agDateColumnFilter",
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "loanAmount",
    "headerName": "Loan Amount",
    "width": 150,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    detailValueFormatters: ['currency'],
    detailCategory: 1,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "isENoteFlag",
    "headerName": "eNote",
    "width": 100,
    "filter": "agSetColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "variance",
    "headerName": "Variance",
    "width": 150,
    "filter": "agNumberColumnFilter",
    comparator: CustomSorting.sortFloat,
    detailCategory: 1,
    cellStyle: CellClassRules.varianceHighlight,
  },
  {
    "field": "wireAmount",
    "headerName": "Wire Amount",
    "width": 150,
    "filter": "agNumberColumnFilter",
    valueFormatter: CurrencyFormatter.setCurrencyFormat,
    detailValueFormatters: ['currency'],
    detailCategory: 1,
    cellStyle: { 'text-align': 'right' }
  },
  {
    "field": "Exception",
    "headerName": "Exception",
    "width": 150,
    detailValueFormatters: ['y/n'],
    "filter": "agSetColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "BatchNumber",
    "headerName": "Batch Number",
    "width": 180,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "manualWireInstructions",
    "headerName": "Manual wire Instr",
    "width": 150,
    detailValueFormatters: ['y/n'],
    "filter": "agSetColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "bankABA",
    "headerName": "ABA",
    "width": 150,
    detailCategory: 1,
    "filter": "agTextColumnFilter",
  },
  {
    "field": "bankAccountNumber",
    "headerName": "Wire Bank Account#",
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "scheduledDate",
    "headerName": "Advance Date",
    "width": 180,
    cellEditor:'dateEditorComponent',
    cellRenderer: 'formatDateRenderer',
    detailValueFormatters: ['date'],
    detailCategory: 3,
    "filter": "agDateColumnFilter",
    editable: ConditionalEditor.setAdvanceDateEditor,
    suppressKeyboardEvent: (params) => {
      return params.event.which == 13;
    },
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "disbursementDate",
    "headerName": "Disbursement Date",
    "width": 180,
    detailValueFormatters: ['date'],
    detailCategory: 3,
    "filter": "agDateColumnFilter",
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "loanPurpose",
    "headerName": "Loan Purpose",
    "filter": "agSetColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "eorDate",
    "headerName": "EOR Date",
    "width": 180,
    detailValueFormatters: ['date'],
    detailCategory: 3,
    "filter": "agDateColumnFilter",
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "state",
    "headerName": "State",
    "width": 130,
    "filter": "agSetColumnFilter",
    detailCategory: 2,
  },
  {
    "field": "county",
    "headerName": "Property County",
    "filter": "agSetColumnFilter",
    "width": 160,
    detailCategory: 2,
  },
  {
    "field": "funderName",
    "headerName": "Funder",
    "width": 150,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "loanType",
    "headerName": "Loan Type",
    "filter": "agSetColumnFilter",
    "width": 150,
    detailCategory: 1,
  },
  {
    "field": "investor",
    "headerName": "Investor",
    "width": 150,
    "filter": "agSetColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "propertyType",
    "headerName": "Property Type",
    "width": 150,
    "filter": "agSetColumnFilter",
    detailCategory: 2,
  },
  {
    "field": "planDescription",
    "headerName": "Program Type",
    "width": 150,
    "filter": "agSetColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "bankAccountName",
    "headerName": "Closing Agent",
    "filter": "agSetColumnFilter",
    "width": 200,
  },
  {
    "field": "documentDate",
    "headerName": "Document Date",
    "width": 180,
    detailValueFormatters: ['date'],
    detailCategory: 3,
    "filter": "agDateColumnFilter",
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "representativeCreditScore",
    "headerName": "FICO",
    "filter": "agNumberColumnFilter",
    "width": 100,
    detailCategory: 1,
  },
  {
    "field": "ltvRatio",
    "headerName": "LTV",
    "width": 100,
    comparator: CustomSorting.sortFloat,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "cltvRatio",
    "headerName": "CLTV",
    "width": 100,
    comparator: CustomSorting.sortFloat,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "lienPosition",
    "headerName": "Lien Position",
    "width": 180,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "lockDate",
    "headerName": "Lock Expiration",
    "width": 180,
    detailValueFormatters: ['date'],
    detailCategory: 3,
    "filter": "agDateColumnFilter",
    comparator: DateFormattor.dateComparator
  },
  {
    "field": "backDTI",
    "headerName": "Back DTI",
    "width": 120,
    comparator: CustomSorting.sortFloat,
    "filter": "agTextColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "fundingType",
    "headerName": "WET/DRY",
    "width": 120,
    "filter": "agSetColumnFilter",
    detailCategory: 1,
  },
  {
    "field": "occupancyType",
    "headerName": "Occupancy Type",
    "width": 160,
    "filter": "agSetColumnFilter",
    detailCategory: 2,
  },
  {
    "field": "action",
    "headerName": "",
    "headerComponent": "clearFilterHeader",
    "headerClass": "flex-row-reverse",
    "width": 300,
    "active": true,
    "cellRenderer": "actionColumnRendererComponent",
    "resizable": false,
    "pinned": "right",
    "suppressMovable": true,
    "suppressSorting": true,
    "suppressRowClickSelection": true,
    "lockPinned": true,
    "cellRendererParams": {
      "onClick": "this.onBtnClick1.bind(this)"
    },
    cellClass: params => {
      return params.data ? 'wlms-actionsColumn-' + params.data.loanNumber : '';
    },
    showRowGroup: true,
    "suppressColumnsToolPanel": true,
    "sortable": false,
    "suppressMenu": true
  }
];
