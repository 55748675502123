import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportHistoryComponent } from './export-history.component';
import { UiKitModule } from '@wlms-web/ui-kit';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UtilsModule } from '@wlms-web/utils';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FeaturesPageLayoutModule } from '@wlms-web/features/page-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@wlms-web/material';
import { CsvGeneratorModalComponent } from './csv-generator-modal/csv-generator-modal/csv-generator-modal.component';
import { FundingBatchDetailModalComponent } from './funding-batch-detail-modal/funding-batch-detail-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CsvGeneratorResultModalComponent } from './csv-generator-result-modal/csv-generator-result-modal/csv-generator-result-modal.component';@NgModule({ declarations: [ExportHistoryComponent, CsvGeneratorModalComponent, CsvGeneratorResultModalComponent, FundingBatchDetailModalComponent],
    exports: [ExportHistoryComponent], imports: [CommonModule,
        BsDropdownModule.forRoot(),
        UtilsModule,
        UiKitModule,
        FeaturesPageLayoutModule,
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        MaterialModule, FormsModule, ReactiveFormsModule], providers: [provideHttpClient(withInterceptorsFromDi())] })

export class ExportHistoryModule {}
